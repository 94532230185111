var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter", class: _vm.actionInputStyles },
    [
      _c(
        "div",
        { staticClass: "filter-inputs" },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.action_name,
                  expression: "action_name"
                }
              ],
              staticClass: "action__question",
              class: { "full-width": !_vm.showActionInput },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.action_name = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function($event) {
                    return _vm.resetAction()
                  }
                ]
              }
            },
            _vm._l(_vm.actionTypes, function(attribute) {
              return _c(
                "option",
                { key: attribute.key, domProps: { value: attribute.key } },
                [_vm._v("\n        " + _vm._s(attribute.label) + "\n      ")]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.showActionInput
            ? _c("div", { staticClass: "filter__answer--wrap" }, [
                _vm.inputType
                  ? _c(
                      "div",
                      [
                        _vm.inputType === "search_select"
                          ? _c(
                              "div",
                              { staticClass: "multiselect-wrap--small" },
                              [
                                _c("multiselect", {
                                  attrs: {
                                    "track-by": "id",
                                    label: "name",
                                    placeholder: _vm.$t(
                                      "FORMS.MULTISELECT.SELECT"
                                    ),
                                    "selected-label": "",
                                    "select-label": _vm.$t(
                                      "FORMS.MULTISELECT.ENTER_TO_SELECT"
                                    ),
                                    "deselect-label": "",
                                    "max-height": 160,
                                    options: _vm.dropdownValues,
                                    "allow-empty": false,
                                    "option-height": 104
                                  },
                                  model: {
                                    value: _vm.action_params,
                                    callback: function($$v) {
                                      _vm.action_params = $$v
                                    },
                                    expression: "action_params"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm.inputType === "multi_select"
                          ? _c(
                              "div",
                              { staticClass: "multiselect-wrap--small" },
                              [
                                _c("multiselect", {
                                  attrs: {
                                    "track-by": "id",
                                    label: "name",
                                    placeholder: _vm.$t(
                                      "FORMS.MULTISELECT.SELECT"
                                    ),
                                    multiple: true,
                                    "selected-label": "",
                                    "select-label": _vm.$t(
                                      "FORMS.MULTISELECT.ENTER_TO_SELECT"
                                    ),
                                    "deselect-label": "",
                                    "max-height": 160,
                                    options: _vm.dropdownValues,
                                    "allow-empty": false,
                                    "option-height": 104
                                  },
                                  model: {
                                    value: _vm.action_params,
                                    callback: function($$v) {
                                      _vm.action_params = $$v
                                    },
                                    expression: "action_params"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm.inputType === "email"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.action_params,
                                  expression: "action_params"
                                }
                              ],
                              staticClass: "answer--text-input",
                              attrs: {
                                type: "email",
                                placeholder: "Enter email"
                              },
                              domProps: { value: _vm.action_params },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.action_params = $event.target.value
                                }
                              }
                            })
                          : _vm.inputType === "url"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.action_params,
                                  expression: "action_params"
                                }
                              ],
                              staticClass: "answer--text-input",
                              attrs: { type: "url", placeholder: "Enter url" },
                              domProps: { value: _vm.action_params },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.action_params = $event.target.value
                                }
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.inputType === "attachment"
                          ? _c("automation-action-file-input", {
                              attrs: {
                                "initial-file-name": _vm.initialFileName
                              },
                              model: {
                                value: _vm.action_params,
                                callback: function($$v) {
                                  _vm.action_params = $$v
                                },
                                expression: "action_params"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isMacro
            ? _c("woot-button", {
                attrs: {
                  icon: "dismiss",
                  variant: "clear",
                  "color-scheme": "secondary"
                },
                on: { click: _vm.removeAction }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.inputType === "team_message"
        ? _c("automation-action-team-message-input", {
            attrs: { teams: _vm.dropdownValues },
            model: {
              value: _vm.action_params,
              callback: function($$v) {
                _vm.action_params = $$v
              },
              expression: "action_params"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.inputType === "textarea"
        ? _c("woot-message-editor", {
            staticClass: "action-message",
            attrs: {
              rows: "4",
              placeholder: _vm.$t(
                "AUTOMATION.ACTION.TEAM_MESSAGE_INPUT_PLACEHOLDER"
              )
            },
            model: {
              value: _vm.castMessageVmodel,
              callback: function($$v) {
                _vm.castMessageVmodel = $$v
              },
              expression: "castMessageVmodel"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.v.action_params.$dirty && _vm.v.action_params.$error
        ? _c("p", { staticClass: "filter-error" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("FILTER.EMPTY_VALUE_ERROR")) + "\n  "
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }