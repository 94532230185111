var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isATwilioChannel
    ? _c(
        "div",
        { staticClass: "settings--content" },
        [
          _c(
            "settings-section",
            {
              attrs: {
                title: _vm.$t("INBOX_MGMT.ADD.TWILIO.API_CALLBACK.TITLE"),
                "sub-title": _vm.$t(
                  "INBOX_MGMT.ADD.TWILIO.API_CALLBACK.SUBTITLE"
                )
              }
            },
            [
              _c("woot-code", {
                attrs: { script: _vm.inbox.callback_webhook_url, lang: "html" }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm.isALineChannel
    ? _c(
        "div",
        { staticClass: "settings--content" },
        [
          _c(
            "settings-section",
            {
              attrs: {
                title: _vm.$t("INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.TITLE"),
                "sub-title": _vm.$t(
                  "INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.SUBTITLE"
                )
              }
            },
            [
              _c("woot-code", {
                attrs: { script: _vm.inbox.callback_webhook_url, lang: "html" }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm.isAWebWidgetInbox
    ? _c("div", [
        _c(
          "div",
          { staticClass: "settings--content" },
          [
            _c(
              "settings-section",
              {
                attrs: {
                  title: _vm.$t("INBOX_MGMT.SETTINGS_POPUP.MESSENGER_HEADING"),
                  "sub-title": _vm.$t(
                    "INBOX_MGMT.SETTINGS_POPUP.MESSENGER_SUB_HEAD"
                  )
                }
              },
              [
                _c("woot-code", {
                  attrs: { script: _vm.inbox.web_widget_script }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "settings-section",
              {
                attrs: {
                  title: _vm.$t("INBOX_MGMT.SETTINGS_POPUP.HMAC_VERIFICATION"),
                  "sub-title": _vm.$t(
                    "INBOX_MGMT.SETTINGS_POPUP.HMAC_DESCRIPTION"
                  )
                }
              },
              [_c("woot-code", { attrs: { script: _vm.inbox.hmac_token } })],
              1
            ),
            _vm._v(" "),
            _c(
              "settings-section",
              {
                attrs: {
                  title: _vm.$t(
                    "INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_VERIFICATION"
                  ),
                  "sub-title": _vm.$t(
                    "INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_DESCRIPTION"
                  )
                }
              },
              [
                _c("div", { staticClass: "enter-to-send--checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.hmacMandatory,
                        expression: "hmacMandatory"
                      }
                    ],
                    attrs: { id: "hmacMandatory", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.hmacMandatory)
                        ? _vm._i(_vm.hmacMandatory, null) > -1
                        : _vm.hmacMandatory
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.hmacMandatory,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.hmacMandatory = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.hmacMandatory = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.hmacMandatory = $$c
                          }
                        },
                        _vm.handleHmacFlag
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "hmacMandatory" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("INBOX_MGMT.EDIT.ENABLE_HMAC.LABEL")) +
                        "\n        "
                    )
                  ])
                ])
              ]
            )
          ],
          1
        )
      ])
    : _vm.isAPIInbox
    ? _c(
        "div",
        { staticClass: "settings--content" },
        [
          _c(
            "settings-section",
            {
              attrs: {
                title: _vm.$t("INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER"),
                "sub-title": _vm.$t(
                  "INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER_SUB_TEXT"
                )
              }
            },
            [
              _c("woot-code", { attrs: { script: _vm.inbox.inbox_identifier } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "settings-section",
            {
              attrs: {
                title: _vm.$t("INBOX_MGMT.SETTINGS_POPUP.HMAC_VERIFICATION"),
                "sub-title": _vm.$t(
                  "INBOX_MGMT.SETTINGS_POPUP.HMAC_DESCRIPTION"
                )
              }
            },
            [_c("woot-code", { attrs: { script: _vm.inbox.hmac_token } })],
            1
          ),
          _vm._v(" "),
          _c(
            "settings-section",
            {
              attrs: {
                title: _vm.$t(
                  "INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_VERIFICATION"
                ),
                "sub-title": _vm.$t(
                  "INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_DESCRIPTION"
                )
              }
            },
            [
              _c("div", { staticClass: "enter-to-send--checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hmacMandatory,
                      expression: "hmacMandatory"
                    }
                  ],
                  attrs: { id: "hmacMandatory", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.hmacMandatory)
                      ? _vm._i(_vm.hmacMandatory, null) > -1
                      : _vm.hmacMandatory
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.hmacMandatory,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.hmacMandatory = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.hmacMandatory = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.hmacMandatory = $$c
                        }
                      },
                      _vm.handleHmacFlag
                    ]
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "hmacMandatory" } }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("INBOX_MGMT.EDIT.ENABLE_HMAC.LABEL")) +
                      "\n      "
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    : _vm.isAnEmailChannel
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "settings--content" },
            [
              _c(
                "settings-section",
                {
                  attrs: {
                    title: _vm.$t(
                      "INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_TITLE"
                    ),
                    "sub-title": _vm.$t(
                      "INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_SUB_TEXT"
                    )
                  }
                },
                [
                  _c("woot-code", {
                    attrs: { script: _vm.inbox.forward_to_email }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("imap-settings", { attrs: { inbox: _vm.inbox } }),
          _vm._v(" "),
          _vm.inbox.imap_enabled
            ? _c("smtp-settings", { attrs: { inbox: _vm.inbox } })
            : _vm._e(),
          _vm._v(" "),
          _vm.inbox.microsoft_reauthorization
            ? _c("microsoft-reauthorize", { attrs: { inbox: _vm.inbox } })
            : _vm._e()
        ],
        1
      )
    : _vm.isAWhatsAppChannel && !_vm.isATwilioChannel
    ? _c("div", [
        _vm.inbox.provider_config
          ? _c(
              "div",
              { staticClass: "settings--content" },
              [
                _c(
                  "settings-section",
                  {
                    attrs: {
                      title: _vm.$t(
                        "INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_TITLE"
                      ),
                      "sub-title": _vm.$t(
                        "INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_SUBHEADER"
                      )
                    }
                  },
                  [
                    _c("woot-code", {
                      attrs: { script: _vm.inbox.provider_config.api_key }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }