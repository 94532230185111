import { render, staticRenderFns } from "./ContactInfoRow.vue?vue&type=template&id=411a1478&scoped=true&"
import script from "./ContactInfoRow.vue?vue&type=script&lang=js&"
export * from "./ContactInfoRow.vue?vue&type=script&lang=js&"
import style0 from "./ContactInfoRow.vue?vue&type=style&index=0&id=411a1478&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "411a1478",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/Spinocial/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('411a1478')) {
      api.createRecord('411a1478', component.options)
    } else {
      api.reload('411a1478', component.options)
    }
    module.hot.accept("./ContactInfoRow.vue?vue&type=template&id=411a1478&scoped=true&", function () {
      api.rerender('411a1478', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/conversation/contact/ContactInfoRow.vue"
export default component.exports