var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "medium-3 bg-white contact--panel" },
    [
      _c("woot-button", {
        staticClass: "close-button clear secondary",
        attrs: { icon: "chevron-right" },
        on: { click: _vm.onPanelToggle }
      }),
      _vm._v(" "),
      _c("contact-info", {
        attrs: { contact: _vm.contact, "channel-type": _vm.channelType }
      }),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "list-group",
          attrs: {
            list: _vm.conversationSidebarItems,
            disabled: !_vm.dragEnabled,
            animation: "200",
            "ghost-class": "ghost",
            handle: ".drag-handle"
          },
          on: {
            start: function($event) {
              _vm.dragging = true
            },
            end: _vm.onDragEnd
          }
        },
        [
          _c(
            "transition-group",
            _vm._l(_vm.conversationSidebarItems, function(element) {
              return _c(
                "div",
                { key: element.name, staticClass: "list-group-item" },
                [
                  element.name === "conversation_actions"
                    ? _c(
                        "div",
                        { staticClass: "conversation--actions" },
                        [
                          _c(
                            "accordion-item",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_ACTIONS"
                                ),
                                "is-open": _vm.isContactSidebarItemOpen(
                                  "is_conv_actions_open"
                                )
                              },
                              on: {
                                click: function(value) {
                                  return _vm.toggleSidebarUIState(
                                    "is_conv_actions_open",
                                    value
                                  )
                                }
                              }
                            },
                            [
                              _c("conversation-action", {
                                attrs: {
                                  "conversation-id": _vm.conversationId,
                                  "inbox-id": _vm.inboxId
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : element.name === "conversation_info"
                    ? _c(
                        "div",
                        [
                          _c(
                            "accordion-item",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_INFO"
                                ),
                                "is-open": _vm.isContactSidebarItemOpen(
                                  "is_conv_details_open"
                                ),
                                compact: ""
                              },
                              on: {
                                click: function(value) {
                                  return _vm.toggleSidebarUIState(
                                    "is_conv_details_open",
                                    value
                                  )
                                }
                              }
                            },
                            [
                              _c("conversation-info", {
                                attrs: {
                                  "conversation-attributes":
                                    _vm.conversationAdditionalAttributes,
                                  "contact-attributes":
                                    _vm.contactAdditionalAttributes
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : element.name === "contact_attributes"
                    ? _c(
                        "div",
                        [
                          _c(
                            "accordion-item",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "CONVERSATION_SIDEBAR.ACCORDION.CONTACT_ATTRIBUTES"
                                ),
                                "is-open": _vm.isContactSidebarItemOpen(
                                  "is_contact_attributes_open"
                                ),
                                compact: ""
                              },
                              on: {
                                click: function(value) {
                                  return _vm.toggleSidebarUIState(
                                    "is_contact_attributes_open",
                                    value
                                  )
                                }
                              }
                            },
                            [
                              _c("custom-attributes", {
                                staticClass: "even",
                                attrs: {
                                  "attribute-type": "contact_attribute",
                                  "attribute-class": "conversation--attribute",
                                  "contact-id": _vm.contact.id
                                }
                              }),
                              _vm._v(" "),
                              _c("custom-attribute-selector", {
                                attrs: {
                                  "attribute-type": "contact_attribute",
                                  "contact-id": _vm.contact.id
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : element.name === "previous_conversation"
                    ? _c(
                        "div",
                        [
                          _vm.contact.id
                            ? _c(
                                "accordion-item",
                                {
                                  attrs: {
                                    title: _vm.$t(
                                      "CONVERSATION_SIDEBAR.ACCORDION.PREVIOUS_CONVERSATION"
                                    ),
                                    "is-open": _vm.isContactSidebarItemOpen(
                                      "is_previous_conv_open"
                                    )
                                  },
                                  on: {
                                    click: function(value) {
                                      return _vm.toggleSidebarUIState(
                                        "is_previous_conv_open",
                                        value
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("contact-conversations", {
                                    attrs: {
                                      "contact-id": _vm.contact.id,
                                      "conversation-id": _vm.conversationId
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : element.name === "macros"
                    ? _c(
                        "woot-feature-toggle",
                        { attrs: { "feature-key": "macros" } },
                        [
                          _c(
                            "accordion-item",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "CONVERSATION_SIDEBAR.ACCORDION.MACROS"
                                ),
                                "is-open": _vm.isContactSidebarItemOpen(
                                  "is_macro_open"
                                ),
                                compact: ""
                              },
                              on: {
                                click: function(value) {
                                  return _vm.toggleSidebarUIState(
                                    "is_macro_open",
                                    value
                                  )
                                }
                              }
                            },
                            [
                              _c("macros-list", {
                                attrs: { "conversation-id": _vm.conversationId }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }