import { render, staticRenderFns } from "./EditLabel.vue?vue&type=template&id=3bc75277&"
import script from "./EditLabel.vue?vue&type=script&lang=js&"
export * from "./EditLabel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/Spinocial/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3bc75277')) {
      api.createRecord('3bc75277', component.options)
    } else {
      api.reload('3bc75277', component.options)
    }
    module.hot.accept("./EditLabel.vue?vue&type=template&id=3bc75277&", function () {
      api.rerender('3bc75277', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/settings/labels/EditLabel.vue"
export default component.exports