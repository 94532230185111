var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor-root" },
    [
      _vm.showUserMentions && _vm.isPrivate
        ? _c("tag-agents", {
            attrs: { "search-key": _vm.mentionSearchKey },
            on: { click: _vm.insertMentionNode }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showCannedMenu && !_vm.isPrivate
        ? _c("canned-response", {
            attrs: { "search-key": _vm.cannedSearchTerm },
            on: { click: _vm.insertCannedResponse }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { ref: "editor" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }