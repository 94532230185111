var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown-wrap" }, [
    _c("div", { staticClass: "search-wrap" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search"
          }
        ],
        ref: "searchbar",
        staticClass: "search-input",
        attrs: {
          type: "text",
          autofocus: "true",
          placeholder: _vm.inputPlaceholder
        },
        domProps: { value: _vm.search },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "list-scroll-container" }, [
      _c(
        "div",
        { staticClass: "multiselect-dropdown--list" },
        [
          _c(
            "woot-dropdown-menu",
            _vm._l(_vm.filteredOptions, function(option) {
              return _c(
                "woot-dropdown-item",
                { key: option.id },
                [
                  _c(
                    "woot-button",
                    {
                      staticClass: "multiselect-dropdown--item",
                      class: {
                        active: _vm.isActive(option)
                      },
                      attrs: {
                        variant: _vm.isActive(option) ? "hollow" : "clear",
                        "color-scheme": "secondary"
                      },
                      on: {
                        click: function() {
                          return _vm.onclick(option)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "user-wrap" },
                        [
                          _vm.hasThumbnail
                            ? _c("Thumbnail", {
                                attrs: {
                                  src: option.thumbnail,
                                  size: "24px",
                                  username: option.name,
                                  status: option.availability_status,
                                  "has-border": ""
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "name-wrap" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "name text-truncate text-block-title",
                                  attrs: { title: option.name }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(option.name) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              option.id ===
                              (_vm.selectedItem && _vm.selectedItem.id)
                                ? _c("fluent-icon", {
                                    attrs: { icon: "checkmark" }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.noResult
            ? _c(
                "h4",
                { staticClass: "no-result text-truncate text-block-title" },
                [_vm._v("\n        " + _vm._s(_vm.noSearchResult) + "\n      ")]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }