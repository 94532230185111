var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", { attrs: { "header-title": _vm.pageTitle } }),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "row medium-8",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.editCannedResponse()
                }
              }
            },
            [
              _c("div", { staticClass: "medium-12 columns" }, [
                _c("label", { class: { error: _vm.$v.shortCode.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("CANNED_MGMT.EDIT.FORM.SHORT_CODE.LABEL")) +
                      "\n          "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.shortCode,
                        expression: "shortCode",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "CANNED_MGMT.EDIT.FORM.SHORT_CODE.PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.shortCode },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.shortCode = $event.target.value.trim()
                        },
                        _vm.$v.shortCode.$touch
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns" }, [
                _c("label", { class: { error: _vm.$v.content.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("CANNED_MGMT.EDIT.FORM.CONTENT.LABEL")) +
                      "\n          "
                  ),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.content,
                        expression: "content",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      rows: "5",
                      type: "text",
                      placeholder: _vm.$t(
                        "CANNED_MGMT.EDIT.FORM.CONTENT.PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.content },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.content = $event.target.value.trim()
                        },
                        _vm.$v.content.$touch
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "div",
                  { staticClass: "medium-12 columns" },
                  [
                    _c("woot-submit-button", {
                      attrs: {
                        disabled:
                          _vm.$v.content.$invalid ||
                          _vm.$v.shortCode.$invalid ||
                          _vm.editCanned.showLoading,
                        "button-text": _vm.$t("CANNED_MGMT.EDIT.FORM.SUBMIT"),
                        loading: _vm.editCanned.showLoading
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button clear",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onClose($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("CANNED_MGMT.EDIT.CANCEL_BUTTON_TEXT")
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }