import { render, staticRenderFns } from "./ContactDropdownItem.vue?vue&type=template&id=620c7f1a&scoped=true&"
import script from "./ContactDropdownItem.vue?vue&type=script&lang=js&"
export * from "./ContactDropdownItem.vue?vue&type=script&lang=js&"
import style0 from "./ContactDropdownItem.vue?vue&type=style&index=0&id=620c7f1a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620c7f1a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/Spinocial/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('620c7f1a')) {
      api.createRecord('620c7f1a', component.options)
    } else {
      api.reload('620c7f1a', component.options)
    }
    module.hot.accept("./ContactDropdownItem.vue?vue&type=template&id=620c7f1a&scoped=true&", function () {
      api.rerender('620c7f1a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/modules/contact/components/ContactDropdownItem.vue"
export default component.exports