var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "context-menu" },
    [
      _vm.isCannedResponseModalOpen && _vm.showCannedResponseOption
        ? _c(
            "woot-modal",
            {
              attrs: {
                show: _vm.isCannedResponseModalOpen,
                "on-close": _vm.hideCannedResponseModal
              },
              on: {
                "update:show": function($event) {
                  _vm.isCannedResponseModalOpen = $event
                }
              }
            },
            [
              _c("add-canned-modal", {
                attrs: {
                  "response-content": _vm.plainTextContent,
                  "on-close": _vm.hideCannedResponseModal
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("woot-button", {
        attrs: {
          icon: "more-vertical",
          "color-scheme": "secondary",
          variant: "clear",
          size: "small"
        },
        on: { click: _vm.handleContextMenuClick }
      }),
      _vm._v(" "),
      _vm.isOpen && !_vm.isCannedResponseModalOpen
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.handleContextMenuClick,
                  expression: "handleContextMenuClick"
                }
              ],
              staticClass: "dropdown-pane dropdown-pane--open",
              class: "dropdown-pane--" + _vm.menuPosition
            },
            [
              _c(
                "woot-dropdown-menu",
                [
                  _vm.showCopy
                    ? _c(
                        "woot-dropdown-item",
                        [
                          _c(
                            "woot-dropdown-item",
                            [
                              _c(
                                "woot-button",
                                {
                                  attrs: {
                                    variant: "clear",
                                    "color-scheme": "alert",
                                    size: "small",
                                    icon: "delete"
                                  },
                                  on: { click: _vm.handleDelete }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "CONVERSATION.CONTEXT_MENU.DELETE"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "woot-button",
                            {
                              attrs: {
                                variant: "clear",
                                size: "small",
                                icon: "clipboard",
                                "color-scheme": "secondary"
                              },
                              on: { click: _vm.handleCopy }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("CONVERSATION.CONTEXT_MENU.COPY")
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "woot-dropdown-item",
                    [
                      _vm.showCannedResponseOption
                        ? _c(
                            "woot-button",
                            {
                              attrs: {
                                variant: "clear",
                                size: "small",
                                icon: "comment-add",
                                "color-scheme": "secondary"
                              },
                              on: { click: _vm.showCannedResponseModal }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "CONVERSATION.CONTEXT_MENU.CREATE_A_CANNED_RESPONSE"
                                    )
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }