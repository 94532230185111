<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CREATE_CASE_IN_CRM.TITLE')"
        :header-content="$t('CREATE_CASE_IN_CRM.DESC')"
      />
      <form @submit.prevent="onSubmit">
        <div class="modal-footer">
          <div class="medium-12 row">
            <woot-submit-button
              :button-text="$t('CREATE_CASE_IN_CRM.SUBMIT')"
              :disabled="false"
            />
            <button class="button clear" @click.prevent="onCancel">
              {{ $t('CREATE_CASE_IN_CRM.CANCEL') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import axios from 'axios';

export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currentChat: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    async onSubmit() {
      this.isSubmitting = false;
      try {
        const apiUrl =
          process.env.API_BASE_URL + '/api/Case/SaveCaseFromConversation';
        axios
          .post(apiUrl, {
            AccountKey: process.env.CRM_ACCOUNT_KEY,
            AccountId: this.currentChat.account_id,
            ChannelId: this.currentChat.ChannelId,
            InboxId: this.currentChat.inbox_id,
            ConversationId: this.currentChat.id,
          })
          .then(response => {
            this.showAlert(this.$t('CONVERSATION.CRM.CREATE_CASE_SUCCESS'));
            if (response.data.Flag === 1) {
              setTimeout(function() {
                window.location.reload();
              }, 2500);
            } else {
              this.showAlert(this.$t('CONVERSATION.CRM.CREATE_CASE_ERROR'));
            }
          });
        this.onCancel();
      } catch (error) {
        this.showAlert(this.$t('CONVERSATION.CRM.CREATE_CASE_ERROR'));
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
